import {CompareApi, ComparisonContext, Status} from "../types/CompareApi";
import {eventQBus} from "../types/EventQBus";
import {setLocation} from "../utils/Global";
import {OnTileLoaded} from "../types/Tile";
import {PandaCompareApi} from "./api/PandaCompareApi";
import CompareSheet from "./CompareSheet";

/**
 *
 */
let compareApi: CompareApi;

/**
 *
 *
 *
 *
 *
 */
function compareAddRemoveTracking(variationId: string, action: string, status: string) {
  const listPosition = locateListpositionofVariation(variationId);
  /*                                    */
  eventQBus.emit(
    "tracking.bct.submitAction",
    { san_ComparisonActivity: action, san_ComparisonStatus: status },
    {
      name: action === "add" ? "add" : "delete",
      features: [
        {
          id: "compare" + variationId,
          name: "tile",
          status: action === "add" ? "added" : "deleted",
          variationId: variationId,
          position: listPosition,
        },
      ],
    },
  );
}

/**
 *
 *
 */
function locateListpositionofVariation(variationId: string) {
  const variationTile = document.querySelector(`.find_tile[data-variation-id='${variationId}']`);
  const prod = variationTile?.closest(".product") as HTMLElement;
  return Number(prod?.dataset.listPosition);
}

/**
 *
 */
export function startComparison() {
  compareApi
    .getProductComparisonLink()
    .then(navigateTo)
    .catch((reason) => console.log(reason));
}

function navigateTo(link: string) {
  /*                                    */
  eventQBus.emit("tracking.bct.submitEvent", { san_ComparisonActivity: "start" });

  window.setTimeout(() => {
    setLocation(link);
  }, 0);
}

/**
 *
 */
function onTileLoaded(registration: OnTileLoaded) {
  if (compareApi && registration.comparable) {
    const { variationId } = registration;
    compareApi
      .getSelectedProducts()
      .then((selectedVariations) => {
        if (selectedVariations.indexOf(variationId) !== -1) {
          /*                                    */
          eventQBus.emit(`ftfind.tile.compared`, { variationId: registration.variationId, source: "advisor_scope" });
        } else {
          /*                                    */
          eventQBus.emit(`ftfind.tile.uncompared`, { variationId: registration.variationId, source: "advisor_scope" });
        }
      })
      .catch((reason) => console.log(reason));
  }
}

/**
 *
 *
 *
 *
 *
 *
 */
function handleAdd(context: ComparisonContext, status: Status) {
  const { variationId } = context;

  switch (status) {
    case "OK":
      /*                                    */
      eventQBus.emit(`ftfind.tile.compared`, context);
      compareAddRemoveTracking(variationId, "add", "ok");
      break;
    case "OVERLOAD":
      /*                                    */
      eventQBus.emit(`ftfind.tile.uncompared`, context);
      compareAddRemoveTracking(variationId, "add", "overload");
      break;
    case "ERROR":
      /*                                    */
      eventQBus.emit(`ftfind.tile.uncompared`, context);
      compareAddRemoveTracking(variationId, "add", "error");
      break;
  }
}

/**
 *
 *
 *
 *
 *
 */
function handleRemove(context: ComparisonContext, status: Status) {
  const { variationId } = context;
  let trackingStatus;
  switch (status) {
    case "OK":
      /*                                    */
      eventQBus.emit("ftfind.tile.uncompared", context);
      trackingStatus = "ok";
      break;
    default:
      /*                                    */
      eventQBus.emit("ftfind.tile.compared", context);
      trackingStatus = "error";
      break;
  }

  if (context.source === "advisor_scope") {
    /*                             */
    /*                                                                                         */
    return;
  }

  switch (context.source) {
    case "box":
      compareAddRemoveTracking(variationId, "box_delete", trackingStatus);
      break;
    case "sheet":
      compareAddRemoveTracking(variationId, "sheet_delete", trackingStatus);
      break;
  }
}

/**
 *
 *
 *
 */

function onTileCompare(event: ComparisonContext) {
  const { variationId } = event;
  compareApi
    .addProductToComparison(variationId)
    .then((status) => handleAdd(event, status))
    .then(setComparisonStartFromLayerListener)
    .catch((reason) => console.log(reason));
}

/**
 *
 *
 *
 */
function onTileUncompare(event: ComparisonContext) {
  const { variationId } = event;
  compareApi
    .removeProductFromComparison(variationId)
    .then((status) => handleRemove(event, status))
    .catch((reason) => console.log(reason));
}

/**
 *
 *
 */
function setComparisonStartFromLayerListener() {
  const compareButton = document.getElementsByClassName("js_reptile-start-comparison");
  if (compareButton.length === 1) {
    compareButton[0].addEventListener("click", () => {
      startComparison();
    });
  }
}

export function initComparisonAndSheet() {
  compareApi = new PandaCompareApi();
  initComparison(compareApi);

  CompareSheet.init();

  eventQBus.on("ftfind.dresonRule.resolved", () => {
    /*                                                                        */
    eventQBus.once("ftfind.tilelist.loaded", () => CompareSheet.init());
  });
}

/**
 *
 */
export function initComparison(api: CompareApi) {
  compareApi = api;
  eventQBus.on("ftfind.tile.loaded", onTileLoaded);
  eventQBus.on("ftfind.tile.compare", onTileCompare);
  eventQBus.on("ftfind.tile.uncompare", onTileUncompare);
  eventQBus.on("ftfind.comparison.start", startComparison);
}
