import Slider from "./Slider";
import { Feature, TrackingLabels } from "../../types/Tracking";
import { resolveLinkType } from "../ProductTileClickHandling";

export type RatingSliderTrackingLabels = {
  san_FilledSlotsRatingSlider?: number;
  san_Interaction?: string;
  "wk.san_ProductClickListType"?: string;
  "wk.san_ProductLinkType"?: string;
  "wk.san_ProductType"?: string;
  san_ProductType?: string;
  "wk.san_SliderPosition"?: number;
  san_VariationCount?: number;
};

export default class RatingSlider extends Slider {
  private type: string = "RATING";
  private typeBlackFriday: string = "RATING_BLACK_FRIDAY";

  protected getPathSegment(): string {
    return "rating-slider";
  }

  protected isType(type: string | undefined): boolean {
    return this.type === type || this.typeBlackFriday === type;
  }

  protected getClickTrackingLabel(position: number, ev: MouseEvent): TrackingLabels {
    if (this.avContent) {
      return {
        san_Interaction: "rating_slider_product_tile",
        "wk.san_ProductClickListType": this.avContent.dataset.pt,
        "wk.san_ProductLinkType": resolveLinkType(ev.target as HTMLElement, ev),
        "wk.san_ProductType": "rating_slider",
        "wk.san_SliderPosition": +position,
      };
    }

    return {};
  }

  protected getBaseTrackingLabel(count: number): TrackingLabels {
    return {
      san_FilledSlotsRatingSlider: count,
    };
  }

  protected prepareFeatureTracking(currentSlides: number[]): Feature[] {
    return [
      <Feature>{
        id: "san_rating_slider",
        name: "Rating-Slider",
        status: "loaded",
        labels: {},
      },
    ].concat(
      currentSlides.map((index) => {
        const tile: HTMLElement = document.getElementsByClassName(this.sliderItemClassName).item(index) as HTMLElement;
        const position = tile.dataset.position || "";
        const variationCount = tile.dataset.variationCount || "";
        return <Feature>{
          id: "san_rating_tile_" + position,
          name: "Rating-Slider-Tile",
          status: "loaded",
          parentId: "san_rating_slider",
          position: +position,
          variationId: tile.dataset.variationId,
          labels: {
            san_ProductType: "rating_slider",
            san_VariationCount: +variationCount,
          },
        };
      }),
    );
  }
}
