import { eventQBus } from "../types/EventQBus";
import { Action } from "../types/Tracking";
import { TilelistLoadedEvent } from "../types/Tilelist";

let initialized = false;

function findTileList(): HTMLElement | null {
  return document.getElementById("reptile-search-result");
}

function trackInitialTilelist(): void {
  const element: HTMLElement | null = findTileList();
  const dataset: DOMStringMap | undefined = element?.dataset;

  const { tsFeature } = (element && dataset) || {};

  if (tsFeature) {
    const tsFeatureData = JSON.parse(tsFeature);
    eventQBus.emit("tracking.bct.addFeaturesToPageImpression", tsFeatureData);
  }
}

function trackDynamicTilelist(event: TilelistLoadedEvent) {
  const element: HTMLElement | null = findTileList();
  const { eventMergeId } = event || {};
  const dataset: DOMStringMap | undefined = element?.dataset;
  const { tsFeature } = (element && dataset) || {};

  if (tsFeature) {
    if (eventMergeId) {
      const action: Action = {
        name: "click",
        features: JSON.parse(tsFeature),
      };
      eventQBus.emit("tracking.bct.addActionToEvent", action, eventMergeId);
    } else {
      eventQBus.emit("tracking.bct.addFeaturesToPageImpression", JSON.parse(tsFeature));
    }
  }
}

export function registerTilelistTracking() {
  if (initialized) {
    return;
  }
  initialized = true;

  trackInitialTilelist();
  eventQBus.on("ftfind.tilelist.loaded", trackDynamicTilelist);
}
