import { Tile } from "./Tile";
import { eventQBus } from "../types/EventQBus";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";

export class CompareSheetComponent {
  /*        */

  /**
 *
 */
  private static readonly ID = "reptile_sheet";
  private static readonly TILE_WIDTH = 104; /*                      */

  /*  */

  static readonly sheetClass = "reptile_sheet";

  static readonly openClass = "reptile_sheet--open";
  static readonly closeClass = "reptile_sheet--close";
  static readonly hideClass = "reptile_sheet--hidden";
  static readonly minimizeClass = "reptile_sheet--minimized";

  static readonly curtainClass = "reptile_sheet-curtain";
  static readonly hideCurtainClass = "reptile_sheet-curtain--hidden";
  static readonly noScrollClass = "reptile_sheet--no-scroll";

  static readonly headerClass = "reptile_sheet__header";
  static readonly toggleButtonClass = "reptile_sheet__toggle-button";
  static readonly removeAllClass = "reptile_sheet__remove-all";
  static readonly sheetTitleClass = "reptile_sheet__title";
  static readonly sheetBodyClass = "reptile_sheet__body";
  static readonly sheetMenuClass = "reptile_sheet__menu";

  static readonly slideButtonClass = "reptile_sheet__slide-button";
  static readonly slideButtonVisibilityClass = "reptile_sheet__slide-button--visible";

  /*       */

  private readonly sheet: HTMLElement;
  private readonly sheetBody: HTMLElement;
  private readonly sheetHeader: HTMLElement;
  private readonly sheetMenu: HTMLElement;
  private readonly curtain: HTMLElement;
  private readonly counterTitle: HTMLSpanElement;

  private readonly currentTiles: Map<string, Tile> = new Map();

  private readonly banner: HTMLElement;
  private readonly compareButton: HTMLElement;

  private readonly slideButtonLeft: HTMLElement | null = null;
  private readonly slideButtonRight: HTMLElement | null = null;

  constructor(
    onCurtainClick: (this: CompareSheetComponent) => void,
    onHeaderClick: (this: CompareSheetComponent) => void,
    onRemoveAllClick: (this: CompareSheetComponent) => void,
    onStartComparison: (this: CompareSheetComponent) => void,
    onBannerClick: (this: CompareSheetComponent) => void
  ) {
    this.curtain = document.createElement("div");
    this.curtain.classList.add(CompareSheetComponent.curtainClass, CompareSheetComponent.hideCurtainClass);
    this.curtain.addEventListener("click", onCurtainClick.bind(this));

    this.sheet = document.createElement("div");
    this.sheet.id = CompareSheetComponent.ID;
    this.sheet.classList.add(CompareSheetComponent.sheetClass);

    this.sheetHeader = CompareSheetComponent.makeContentElement(CompareSheetComponent.headerClass, "");
    this.sheetHeader.addEventListener("click", onHeaderClick.bind(this));
    this.sheet.appendChild(this.sheetHeader);

    /*                                                                  */
    this.banner = document.createElement("div");
    this.banner.innerHTML =
      `<div class="pl_banner pl_banner--info ${Tile.tileClass}">` +
      "<span>Füge doch noch ein zweites Produkt von dieser Liste hinzu.</span>" +
      "</div>";
    this.banner.firstChild?.addEventListener("click", onBannerClick.bind(this));

    this.sheetBody = CompareSheetComponent.makeContentElement(CompareSheetComponent.sheetBodyClass, "");
    this.sheet.appendChild(this.sheetBody);

    this.sheetMenu = CompareSheetComponent.makeContentElement(CompareSheetComponent.sheetMenuClass, "");
    this.sheet.appendChild(this.sheetMenu);

    const title = document.createElement("p");
    title.classList.add(CompareSheetComponent.sheetTitleClass);
    title.innerHTML = "Produktvergleich (<span>0 Produkte</span>)";
    this.sheetHeader.appendChild(title);

    /*                                       */
    this.counterTitle = title.childNodes[1] as HTMLSpanElement;

    const toggleButton = document.createElement("button");
    toggleButton.classList.add(CompareSheetComponent.toggleButtonClass);
    toggleButton.innerHTML = "<i></i>";
    this.sheetHeader.appendChild(toggleButton);

    const deleteButton = document.createElement("button");
    this.compareButton = document.createElement("button");

    deleteButton.classList.add("pl_button100--secondary", CompareSheetComponent.removeAllClass);
    deleteButton.textContent = "Alle löschen";
    deleteButton.addEventListener("click", onRemoveAllClick.bind(this));

    this.compareButton.classList.add("pl_button100--primary");
    this.compareButton.textContent = "Auswahl vergleichen";
    this.compareButton.addEventListener("click", onStartComparison.bind(this));

    this.sheetMenu.appendChild(deleteButton);
    this.sheetMenu.appendChild(this.compareButton);

    if (breakpoint.isLarge() || breakpoint.isExtraLarge()) {
      this.slideButtonLeft = CompareSheetComponent.createSlideButton();
      this.slideButtonLeft.addEventListener("click", () => {
        this.slideToLeft();
      });
      this.slideButtonRight = CompareSheetComponent.createSlideButton();

      this.slideButtonRight.addEventListener("click", () => {
        this.slideToRight();
      });
      this.sheet.appendChild(this.slideButtonLeft);

      this.sheet.appendChild(this.slideButtonRight);
    }

    document.body.appendChild(this.sheet);
    document.body.appendChild(this.curtain);
  }

  /*  */

  public static existsInDom(): boolean {
    return document.getElementById(CompareSheetComponent.ID) !== null;
  }

  public removeFromDom() {
    this.sheet.remove();
    this.curtain.remove();
  }

  /*            */

  public addTile(tile: Tile) {
    this.currentTiles.set(tile.variationId, tile);
    this.sheetBody.appendChild(tile.tileWrapper);
    this.updateState();
  }

  public hasTile(variationId: string) {
    return this.currentTiles.has(variationId);
  }

  public removeTile(variationId: string) {
    this.currentTiles.get(variationId)?.tileWrapper?.remove();
    this.currentTiles.delete(variationId);
    this.updateState();
  }

  public forEachTile(visitor: (tile: Tile, key: string) => void) {
    this.currentTiles.forEach(visitor);
  }

  public get amount(): number {
    return this.currentTiles.size;
  }

  /*      */

  public open(): void {
    this.showCurtain();
    this.sheet.classList.add(CompareSheetComponent.openClass);
  }

  public isOpen(): boolean {
    return this.sheet.classList.contains(CompareSheetComponent.openClass);
  }

  public minimize(): void {
    this.hideCurtain();
    this.sheet.classList.add(CompareSheetComponent.minimizeClass);
  }

  public isMinimized(): boolean {
    return this.sheet.classList.contains(CompareSheetComponent.minimizeClass);
  }

  public close(): void {
    if (this.isClosed()) {
      return;
    }
    this.hideCurtain();
    this.sheet.classList.add(CompareSheetComponent.closeClass);
    eventQBus.emit("ftfind.comparison.canceled");
  }

  public hide(): void {
    if (this.isMinimized()) {
      this.sheet.classList.add(CompareSheetComponent.hideClass);
    }
  }

  public unhide(): void {
    this.sheet.classList.remove(CompareSheetComponent.hideClass);
  }

  public isHidden(): boolean {
    return this.sheet.classList.contains(CompareSheetComponent.hideClass);
  }

  public isClosed(): boolean {
    return this.sheet.classList.contains(CompareSheetComponent.closeClass);
  }

  private showCurtain() {
    document.documentElement.classList.add(CompareSheetComponent.noScrollClass);
    this.curtain.classList.remove(CompareSheetComponent.hideCurtainClass);
    this.clearVisibilityState();
  }

  private hideCurtain(): void {
    document.documentElement.classList.remove(CompareSheetComponent.noScrollClass);
    this.curtain.classList.add(CompareSheetComponent.hideCurtainClass);
    this.clearVisibilityState();
  }

  private clearVisibilityState() {
    this.sheet.classList.remove(
      CompareSheetComponent.openClass,
      CompareSheetComponent.minimizeClass,
      CompareSheetComponent.closeClass
    );
  }

  /*     */

  private updateState() {
    this.updateBannerAndButtons();
    this.handleSlideButtonVisibility();
    this.updateCounterTitle();
  }

  private updateBannerAndButtons() {
    if (this.amount == 1) {
      this.sheetBody.appendChild(this.banner);
      this.compareButton.classList.remove("pl_button100--primary");
      this.compareButton.classList.add("pl_button100--secondary");
    } else {
      this.banner.remove();
      if (this.amount > 0) {
        this.compareButton.classList.add("pl_button100--primary");
        this.compareButton.classList.remove("pl_button100--secondary");
      }
    }
  }

  private updateCounterTitle(): void {
    this.counterTitle.textContent = this.amount + " " + (this.amount > 1 ? "Produkte" : "Produkt");
  }

  /*    */

  private slideToLeft(): void {
    this.sheetBody.scrollLeft -= CompareSheetComponent.TILE_WIDTH * 4;
    this.handleSlideButtonVisibility();
  }

  private slideToRight(): void {
    this.sheetBody.scrollLeft += CompareSheetComponent.TILE_WIDTH * 4;
    this.handleSlideButtonVisibility();
  }

  private handleSlideButtonVisibility() {
    if (this.amount > 4) {
      this.slideButtonLeft?.classList.add(CompareSheetComponent.slideButtonVisibilityClass);
      this.slideButtonRight?.classList.add(CompareSheetComponent.slideButtonVisibilityClass);
    }

    if (this.amount <= 4) {
      this.slideButtonLeft?.classList.remove(CompareSheetComponent.slideButtonVisibilityClass);
      this.slideButtonRight?.classList.remove(CompareSheetComponent.slideButtonVisibilityClass);
    }

    if (this.sheetBody.scrollLeft === 0) {
      this.slideButtonLeft?.classList.remove(CompareSheetComponent.slideButtonVisibilityClass);
    }

    /*                                                                 */
    if (this.sheetBody.scrollWidth - this.sheetBody.scrollLeft <= 448) {
      this.slideButtonRight?.classList.remove(CompareSheetComponent.slideButtonVisibilityClass);
    }
  }

  /*      */

  private static createSlideButton(): HTMLElement {
    const slideButton = document.createElement("button");
    slideButton.classList.add("pl_rounded100", CompareSheetComponent.slideButtonClass);
    slideButton.appendChild(document.createElement("i"));
    return slideButton;
  }

  private static makeContentElement(className: string, content: string | undefined): HTMLElement {
    const elem = document.createElement("div");
    elem.classList.add(className);
    if (content) {
      elem.innerHTML = content;
    }
    return elem;
  }
}
