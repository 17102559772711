import { eventQBus } from "../types/EventQBus";
import { logger, status } from "@otto-ec/global-resources/debug";

let initialized = false;

export function registerInvalidPageRedirect() {
  if (initialized) {
    return;
  }
  initialized = true;

  const redirectLink = document.querySelector(".js_san_redirectLink");
  let href: string | null;
  let jsonString;

  const log = logger("reptile.navigation.InvalidPageRedirect");
  if (redirectLink) {
    href = redirectLink.getAttribute("href");
    if (href != null && href.trim().length !== 0) {
      setTimeout(function () {
        jsonString = redirectLink.getAttribute("data-ts-link");
        if (jsonString) {
          eventQBus.emit("tracking.bct.trackOnNextPageImpression", JSON.parse(jsonString));
          /*                            */
          redirectLink.removeAttribute("data-ts-link");
        }
        if (status().activated) {
          log.debug("possibly redirect to href " + href + " (from " + window.location + ")");
        }
        if (href != null && href !== window.location.href) {
          window.location.replace(href);
        }
      });
    }
  }
}
