import { EventLoaderEvents } from "@otto-ec/global-resources/event-loader";
import { TrackingTopics } from "./Tracking";
import { TilelistTopics } from "./Tilelist";
import { DiscountBenefitSheetTopics, TileTopics } from "./Tile";
import { HistoryTopics } from "./TilelistHistory";
import { SortTopics } from "../tilelist/Sort";
import { ComparisonTopics } from "./CompareApi";
import { NavTopics } from "./Nav";
import { eventQBus as eventQBusRaw } from "@otto-ec/global-resources/event-q-bus";
import { AssetTopics } from "./Assets";
import { SponsoredSheetTopics } from "./SponsoredSheet";

export type Topics = TileTopics &
  TilelistTopics &
  TrackingTopics &
  EventLoaderEvents &
  HistoryTopics &
  SortTopics &
  NavTopics &
  ComparisonTopics &
  SponsoredSheetTopics &
  AssetTopics &
  DiscountBenefitSheetTopics;

export const eventQBus = eventQBusRaw.with<Topics>();
